/* istanbul ignore file */

// https://flowbite.com/docs/typography/headings/

import { LightBulbIcon } from "@heroicons/react/24/outline";
import { graphql } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import {
  HowToInstructionsDataFragment,
  HowToStepDataFragment,
} from "../../../graphql-types";
import useFireGAEventInView from "../../hooks/useFireGAEventInView";
import Image from "../GatsbyImage";
import { RichTextRenderer } from "../RichTextRenderer";
import { VideoPlayer } from "../VideoPlayer";

type HowToStepProps = HowToStepDataFragment & {
  index: number;
};

function HowToStep({
  description,
  index,
  title,
  illustration,
}: HowToStepProps) {
  const [seen, setSeen] = useState(false);
  const eventRef = useFireGAEventInView({
    action: "Section in view",
    category: "Engagement",
    label: `How-to step ${index + 1}`,
  });

  const [ref, inView] = useInView();
  const videoEl = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (inView && !seen) {
      setSeen(true);
      videoEl?.current?.load && videoEl.current.load();
    }
  }, [inView, seen]);

  return (
    <li
      className="pb-8 px-6 border-l border-secondary-500 max-w-xs last:border-b-[3px] last:rounded-bl-lg last:border-r-2 border-r-transparent"
      ref={eventRef}
    >
      <h3 className="flex gap-2 items-center mb-1 text-lg font-semibold text-primary-500">
        <span className="absolute flex items-center justify-center size-8 bg-secondary-500 rounded-full -left-4 ring-8 ring-white">
          <LightBulbIcon className="size-8 p-2 text-white" />
        </span>
        {title}
      </h3>
      <RichTextRenderer
        component="div"
        className="inline-html mb-4 text-base font-normal text-gray-500"
        raw={description.raw}
      />
      {illustration.__typename === "Video" && illustration.video.cdnVideo && (
        <div ref={ref}>
          <VideoPlayer
            ref={videoEl}
            controls={false}
            autoPlay={illustration.autoPlay}
            loop={illustration.autoPlay}
            muted={illustration.autoPlay}
            poster={illustration.video.cdnVideo.posterPath}
            title={illustration.video.description || ""}
            path={seen ? illustration.video.cdnVideo.path : ""}
            className="aspect-square w-full"
          />
        </div>
      )}

      {illustration.__typename === "Image" &&
        illustration.asset.gatsbyImageData && (
          <Image
            image={illustration.asset.gatsbyImageData}
            alt={illustration.asset.description as string}
            loading="lazy"
          />
        )}
      {illustration.__typename === "Image" &&
        !illustration.asset.gatsbyImageData &&
        illustration.asset.localFile &&
        illustration.asset.localFile.extension === "svg" &&
        illustration.asset.localFile.publicURL && (
          <img
            alt={illustration.asset.description || ""}
            className="inline-block aspect-square w-full"
            src={illustration.asset.localFile.publicURL}
          />
        )}
    </li>
  );
}

export default function HowToInstructions({
  steps,
}: HowToInstructionsDataFragment) {
  return (
    <ol className="relative max-w-full sm:max-w-xl mx-auto translate-x-2">
      {steps.map((step, index) => (
        <HowToStep key={step.id} index={index} {...step} />
      ))}
    </ol>
  );
}

export const fragment = graphql`
  fragment HowToStepData on HowToStep {
    title
    description {
      raw
    }
    illustration {
      __typename
      ... on Image {
        id
        asset {
          description
          gatsbyImageData(width: 800)
          localFile {
            publicURL
            extension
          }
        }
      }
      ... on Video {
        id
        autoPlay
        video {
          description
          cdnVideo {
            path
            posterPath
          }
        }
      }
    }
  }
  fragment HowToInstructionsData on HowToInstructions {
    steps {
      id
      ...HowToStepData
    }
  }
`;
